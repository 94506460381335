import {
  Icon,
  UilLockAlt,
  UilShield,
  UilUniversity,
} from "@iconscout/react-unicons";
import Image from "next/image";
import { FC, useCallback } from "react";

import { exploreIndicesAnchor, IndexAnchor } from "../../components/Anchor";
import { BubbleText } from "../../components/BubbleText";
import { Button, TextButton } from "../../components/Button";
import { SmoothScrollLink } from "../../components/Link";
import ScreenshotDIPortfolio from "../../public/webstatic/images/screenshot-direct-indexing-hero.png";
import ScreenshotDIPortfolioMobile from "../../public/webstatic/images/screenshot-direct-indexing-hero-mobile.png";
import { classNames } from "../../utils/classNames";
import { BrokerageWebPath } from "../../utils/paths";
import { Avatar } from "@mantine/core";
import { DirectIndexingIcon } from "../../components/DirectIndexingIcon";
import { DirectIndexType } from "@frec-js/common-client";
import { useIsMobile } from "../../hooks/useIsMobile";

export const LandingHero: FC = () => {
  return (
    <>
      {/* !! height depends on screenshot */}
      <section className="grid grid-cols-[auto_minmax(0,_86rem)_auto] grid-rows-[auto_1px_1fr] overflow-x-hidden">
        <div className="">{/* empty */}</div>
        <div className="relative flex flex-col px-6 pb-3 lg:pb-9 lg:px-16 dotted-v lg:border-x -mt-12">
          <h1 className="text-[32px] lg:text-[48px] max-w-[650px] lg:mt-48 mt-28">
            Index investing that
            <br />
            gets you
            <BubbleText className="ml-2 lg:ml-3">more.</BubbleText>
          </h1>
          {/* left margin is width of text minus 50px */}
          <div className="hidden lg:block lg:absolute lg:top-[180px] lg:left-[600px] mt-6 lg:mt-0 z-10">
            <DIScreenshot />
          </div>
        </div>
        <div className="row-span-3">{/* empty */}</div>
        <div className="col-span-2 lg:border-b dotted-h">{/* empty */}</div>
        <div className="">{/* empty */}</div>
        <article className="relative flex flex-col pb-8 lg:pb-32 dotted-v lg:border-x">
          <p className="text-left lg:max-w-[600px] px-8 py-4 lg:p-16 lg:pb-6 lg:text-[20px]">
            Just as easy as investing in an ETF,{" "}
            <span className="whitespace-nowrap">Frec Direct Indexing</span> can
            help you earn more by unlocking tax savings, no matter the market.
            <br />
            <br />
            Done for you, automatically.
          </p>
          <Button
            className="mx-6 mt-2 lg:mx-16 lg:w-fit"
            onClick={() => (location.href = BrokerageWebPath.signup)}
          >
            Get started
          </Button>
          <div className="p-6 lg:hidden">
            <DIScreenshot />
          </div>
          <div className="flex gap-6 mx-6 md:mt-10 lg:mx-16 mt-16">
            <LittleValuePropBullet
              className="border-0 md:border-l md:pl-3"
              text="Member SIPC"
              Icon={UilLockAlt}
            />
            <LittleValuePropBullet
              className="pl-3 border-l"
              text="SEC Registered"
              Icon={UilUniversity}
            />
            <LittleValuePropBullet
              className="pl-3 border-l"
              text="Fiduciary"
              Icon={UilShield}
            />
          </div>
        </article>
      </section>
    </>
  );
};

const DIScreenshot: FC = () => {
  const isMobile = useIsMobile();
  const onClickExploreIndices = useCallback(() => {
    const element = document.getElementById(exploreIndicesAnchor);
    if (!element) return;
    element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  return (
    <div className="relative">
      {isMobile && <ImageDisclaimer className="pl-2 sm:text-right lg:mr-3" />}
      <Image
        src={isMobile ? ScreenshotDIPortfolioMobile : ScreenshotDIPortfolio}
        className="max-h-[100%] lg:max-w-none m-auto lg:m-0"
        width={750}
        height={655}
        alt="Screenshot of Frec’s direct indexing product"
        priority
      />
      <div className="w-full flex flex-col gap-4 absolute lg:-bottom-12 sm:-bottom-11 -bottom-16">
        <div className="w-full flex justify-center">
          <Button
            small
            variant="blueoutline"
            onClick={() => (location.href = BrokerageWebPath.demo)}
          >
            See our live demo {"->"}
          </Button>
        </div>
        <div className="w-full flex justify-center">
          <TextButton
            className="!py-2 !px-0 text-sm text-frecXDarkGray font-normal relative inline-block group"
            onClick={onClickExploreIndices}
          >
            <div className="flex gap-2 items-center">
              <Avatar.Group spacing="sm">
                <Avatar size="sm">
                  <DirectIndexingIcon type={DirectIndexType.Sp500} size="xs" />
                </Avatar>
                <Avatar size="sm">
                  <DirectIndexingIcon
                    type={DirectIndexType.SpInfoTech}
                    size="xs"
                  />
                </Avatar>
                <Avatar size="sm">
                  <DirectIndexingIcon
                    type={DirectIndexType.CrspLargeCap}
                    size="xs"
                  />
                </Avatar>
              </Avatar.Group>
              <span className="relative inline-block group">
                Explore indices ↓
                <span className="absolute left-0 bottom-[-3px] w-full h-[2px] bg-frecXDarkGray origin-left transform scale-x-0 transition-transform duration-300 ease-in-out group-hover:scale-x-100"></span>
              </span>
            </div>
          </TextButton>
        </div>
      </div>
      {!isMobile && <ImageDisclaimer className="pl-2 sm:text-right lg:mr-3" />}
    </div>
  );
};

const LittleValuePropBullet: FC<{
  Icon: Icon;
  text: string;
  className?: string;
}> = ({ Icon, text, className }) => {
  return (
    <SmoothScrollLink
      anchor={IndexAnchor.YourMoneyIsSecure}
      className={classNames("flex border-frecDarkBeige", className)}
    >
      <div className="flex flex-col gap-1">
        <Icon className="w-5 h-5 text-frecBlack/50 shrink-0" />
        <div className="text-frecBlack/50 opacity-80 text-[14px]">{text}</div>
      </div>
    </SmoothScrollLink>
  );
};

const ImageDisclaimer: FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      aria-hidden
      className={classNames(
        "text-[10px] lg:text-[12px] text-frecBlack/50",
        className
      )}
      data-nosnippet
    >
      For illustrative purposes only
    </div>
  );
};
