import Link from "next/link";
import { FC } from "react";

import { AnimatedIcon } from "../../components/Button";

export const TLHBanner: FC = () => {
  return (
    <Link
      href="/tax-loss-harvesting"
      className="flex items-center justify-center h-24 px-6 text-[18px] lg:text-book20 border-b border-black bg-frecNeon group"
    >
      Find out how much you could save on taxes <AnimatedIcon />
    </Link>
  );
};
