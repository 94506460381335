import { colors } from "@frec-js/common";
import Decimal from "decimal.js";
import React, { FC, useMemo } from "react";
import { Area, AreaChart, Legend, ResponsiveContainer, YAxis } from "recharts";

import { useIsMobile } from "../hooks/useIsMobile";
import {
  TimeHorizon,
  useDirectIndexCalculatorData,
} from "./DirectIndexCalculatorData";

type Props = {
  principal: Decimal;
  timeHorizon: TimeHorizon;
};
export const DirectIndexCalculatorGraph: FC<Props> = ({
  principal,
  timeHorizon,
}) => {
  const data = useDirectIndexCalculatorData(principal, timeHorizon);
  const isMobile = useIsMobile();
  // We shift the data down to the x-axis to stop the graph shifting around
  const dataMoved = useMemo(() => {
    const principalNum = principal.toNumber();
    return data?.map((d) => ({
      ...d,
      sp500: d.sp500 - principalNum,
    }));
  }, [data, principal]);
  return data ? (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={400}
        data={dataMoved}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: isMobile ? 2 : -36,
        }}
      >
        <YAxis hide />
        <Area
          type="monotone"
          dataKey="sp500"
          stackId="1"
          stroke={colors.frecBlack}
          strokeWidth={2}
          strokeDasharray="2 6"
          fill="transparent"
        />
        <Area
          type="monotone"
          dataKey="sp500DIDiff"
          stackId="1"
          stroke={colors.frecBlack}
          strokeWidth={2}
          fill={colors.frecNeon}
          fillOpacity={1}
        />
        <Legend
          verticalAlign="bottom"
          align={"right"}
          height={36}
          wrapperStyle={{ bottom: 0 }}
          payload={[
            {
              value: "Frec’s S&P 500 direct index",
              payload: {
                strokeDasharray: "",
              },
              type: "plainline",
              color: "black",
              id: "ID01",
            },
            {
              value: "ETF (SPY)",
              payload: {
                strokeDasharray: "4 8",
              },
              type: "plainline",
              color: "black",
              id: "ID02",
            },
          ]}
        />
      </AreaChart>
    </ResponsiveContainer>
  ) : null;
};
