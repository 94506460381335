import { BrokerageWebPath } from "../utils/paths";
import { Button } from "./Button";

export const StartInvestingButton = () => {
  return (
    <Button
      className="w-fit"
      onClick={() => (location.href = BrokerageWebPath.signup)}
    >
      Start investing
    </Button>
  );
};
