import { DocumentLinks, HelpCenterArticleLinks } from "@frec-js/common";
import {
  Icon,
  UilBalanceScale,
  UilExternalLinkAlt,
  UilMoneyWithdrawal,
} from "@iconscout/react-unicons";
import Link from "next/link";
import { FC, Fragment, ReactNode } from "react";

import { FootnoteAnchor, IndexFootnotes } from "../../components/Anchor";
import { AnimatedIcon } from "../../components/Button";
import { Footnote, TextLink } from "../../components/Link";
import StockTransferIcon from "../../public/webstatic/svg/stock-transfer-icon.svg";
import ChartLineUpIcon from "../../public/webstatic/svg/chart-line-up.svg";
import { FrecWebPath } from "../../utils/paths";
import { Info } from "../../components/Info";

const SupportedAssetsInfo: FC = () => {
  return (
    <Info className="align-middle" position="bottom-start">
      <div className="text-sm">
        Frec supports most US stocks and ETFs traded on major US exchanges.
      </div>
      <Link
        href={
          HelpCenterArticleLinks[
            "What type of assets can you transfer into Frec?"
          ]
        }
        className="flex items-center mt-1 font-medium group hover:underline w-fit gap-1"
        target="_blank"
      >
        See what assets we support <UilExternalLinkAlt className="w-4 h-4" />
      </Link>{" "}
    </Info>
  );
};

const Content = [
  {
    icon: UilBalanceScale,
    title: (
      <>
        Get started with industry-low fees
        <Footnote anchor={FootnoteAnchor.LowerFeesDisclaimerSPY}>
          <sup>{IndexFootnotes.withLowerFeesDisclaimerSPY}</sup>
        </Footnote>
      </>
    ),
    description: (
      <>
        <p>
          You can direct index for as little as a 0.10% annual AUM fee. That’s
          almost on par with SPY’s expense ratio of 0.09%, but with the
          additional benefit of tax loss harvesting and customization. Compare
          that to 0.25% at Wealthfront, 0.40% at traditional brokerages, and
          1.0% for many wealth advisors.
        </p>
        <Link
          href={FrecWebPath.pricing}
          className="flex items-center pr-4 mt-6 font-medium group hover:bg-frecNeon w-fit"
        >
          Learn more about our pricing <AnimatedIcon />
        </Link>
      </>
    ),
  },
  {
    icon: UilMoneyWithdrawal,
    title: "Borrow against your direct index",
    description: (
      <>
        <p>
          Considering selling your stocks for a big purchase? You don’t have to.
          Tap into a portfolio line of credit against your direct index
          portfolio at industry{" "}
          <TextLink href={FrecWebPath.borrowRates}>low fees</TextLink>. Use our{" "}
          <TextLink href={FrecWebPath.sellvsborrow}>
            Sell or Borrow tool
          </TextLink>{" "}
          to see if borrowing from a portfolio line of credit is an option for
          you.
        </p>
        <p className="text-[14px] mt-6 text-frecBlack/70">
          Borrowing against a portfolio line of credit adds to your risks. Read
          more about those risks in{" "}
          <TextLink href={DocumentLinks["Margin Disclosure"]} target="_blank">
            Frec’s Margin Disclosure
          </TextLink>
          .
        </p>
      </>
    ),
  },
  {
    icon: StockTransferIcon,
    title: "Fund with stocks you already own",
    description: (
      <>
        Set up your direct index with your existing stocks. You can easily
        transfer stocks or ETFs to Frec from your current brokerage, then fund
        your direct index, whether in-kind or to be sold tax efficiently over
        time. <SupportedAssetsInfo />
      </>
    ),
  },
  {
    icon: ChartLineUpIcon,
    title: "Trade individual stocks",
    description: (
      <>
        Like other brokerages, you can trade individual stocks and ETFs with
        zero commission fees in Frec’s self-directed account. Trade on your own
        or move them to your direct indexing when you’re ready.{" "}
        <SupportedAssetsInfo />
        <p className="text-[14px] mt-6 text-frecBlack/70">
          Other fees may apply. See our{" "}
          <TextLink
            href={DocumentLinks["Frec’s Pricing & Fee Schedule"]}
            target="_blank"
          >
            Fee Schedule
          </TextLink>{" "}
          for more details.
        </p>
      </>
    ),
  },
];

export const DirectIndexSophisticatedInvesting: FC = () => {
  return (
    <section className="grid grid-cols-[auto_minmax(0,_86rem)_auto] grid-rows-[auto_1px_1fr]">
      <div className="">{/* empty */}</div>
      <div className="col-span-3 border-b dotted-h">{/* empty */}</div>
      <div className="">{/* empty */}</div>
      <div className="flex flex-col-reverse px-6 lg:px-16 dotted-v lg:border-x lg:mx-12 py-9 lg:pt-24 lg:pb-12">
        <h2 className="">Sophisticated investing made simple</h2>
      </div>
      <div className="">{/* empty */}</div>
      {/* row */}
      <div className="col-span-3 border-b dotted-h">{/* empty */}</div>
      {/* row */}
      <div className="">{/* empty */}</div>
      <div className="dotted-v lg:border-x lg:mx-12">
        <div className="lg:grid lg:h-full grid-cols-[1fr_1px_1fr] grid-rows-[1fr_1fr]">
          {Content.map((content, index) => (
            <Fragment key={index}>
              <Item {...content} />
              {index % 2 === 0 && <div className="border-r dotted-v" />}
            </Fragment>
          ))}
        </div>
      </div>
      <div className="">{/* empty */}</div>
    </section>
  );
};

const Item: FC<{
  icon: Icon;
  title: ReactNode;
  description: ReactNode;
}> = ({ icon: Icon, title, description }) => {
  return (
    <div className="px-0 py-8 border-b lg:px-10 lg:py-12 font-regular dotted-h">
      <div className="text-book20 lg:text-[32px] flex items-center justify-center w-[48px] h-[48px] lg:w-[60px] lg:h-[60px] bg-frecDarkBeige rounded-lg mx-6">
        <Icon className="fill-frecBlue text-frecBlue w-[24px] lg:w-[32px] h-[24px] lg:h-[32px]" />
      </div>
      <div className="text-[18px] lg:text-[24px] mx-6 mt-6">{title}</div>
      <div className="lg:text-[18px] mx-6 mt-4">{description}</div>
    </div>
  );
};
