import { UilCheck, UilMinus, UilUserCircle } from "@iconscout/react-unicons";
import { FC, ReactNode } from "react";

import FrecLogo from "../../public/webstatic/logo/frec.svg";
import { Info } from "../../components/Info";

export const DirectIndexTable: FC = () => {
  return (
    <section className="grid grid-cols-[auto_minmax(0,_86rem)_auto] grid-rows-[96px_auto_1fr_32px] lg:pt-0 pt-8">
      <div className="">{/* empty */}</div>
      <div className="px-6 pb-8 lg:px-16 dotted-v lg:border-x lg:mx-12">
        <h2 className="text-center h2">See how we’re different</h2>
      </div>
      <div className="">{/* empty */}</div>
      {/* row */}
      <div className="">{/* empty */}</div>
      <div className="dotted-v lg:border-x lg:mx-12 text-[12px] md:text-[16px]">
        <div className="dotted-v border-x-none lg:border-x lg:mx-12">
          <div className="grid grid-cols-[3fr_2fr_2fr_2fr] grid-rows-[repeat(5,100px_1px)] h-full font-normal dotted-h border-y">
            <div className="flex items-center px-4 lg:px-9 dotted-v border-r  bg-[#E9E5DD]">
              Features
            </div>
            <div className="flex items-center justify-center px-2 border-r lg:px-9 dotted-v bg-frecNeon">
              <FrecLogo
                className="md:w-[84px] w-[33px]"
                width="84"
                src="/webstatic/logo/frec.svg"
                aria-label="Frec"
              />
            </div>
            <div className="flex items-center justify-center px-2 lg:px-9 dotted-v border-r bg-[#E9E5DD]">
              ETFs
            </div>
            <div className="flex items-center justify-center px-2 lg:px-9 dotted-v bg-[#E9E5DD] text-center">
              Robo-advisors
            </div>
            {/* <TableRow label="Low minimums" etfs roboAdvisors frec /> */}
            <TableRow
              label={<span>Invest in multiple direct indices</span>}
              etfs
              frec
              roboAdvisors
            />
            <TableRow
              label="Daily tax loss harvesting of individual stocks"
              frec
            />
            <TableRow
              label="Customize by adding or excluding stocks and sectors"
              frec
            />
            <TableRow
              label={
                <span>
                  Transfer stock into direct indexing with tax efficiency{" "}
                  <Info className="align-middle" position="bottom-start">
                    <div className="text-xs">
                      Eligible for all direct index portfolios that meet the
                      minimum investment amount.
                    </div>
                  </Info>
                </span>
              }
              frec
            />
          </div>
        </div>
        <div
          className="px-6 lg:px-16 py-4 text-[12px] lg:text-[14px] text-frecBlack/50"
          data-nosnippet
        >
          A robo-advisor is considered a digital platform that provides
          automated, algorithm-driven investment services with little human
          interaction. Frec Advisers is considered a robo-advisor. Robo-advisors
          typically charge an advisory fee based on assets in the portfolio.
          However, ETFs are exchange traded funds that are securities that trade
          on stock exchanges and typically charge a management fee shown as its
          expense ratio.
        </div>
        <div className="px-6 w-full flex justify-center my-14">
          <span className="bg-frecDarkBeige rounded-full py-2 px-6 text-sm">
            <UilUserCircle className="inline w-4 h-4 text-frecXDarkGray mr-2 align-middle" />
            <span className="font-medium align-middle">Account support</span>
            <span className="font-normal ml-2 align-middle">
              We support individual, business, and trust accounts.
            </span>
          </span>
        </div>
      </div>
      <div className="">{/* empty */}</div>
      {/* row */}
      <div className=""></div>
      <div className="dotted-v lg:border-x lg:mx-12">{/* empty */}</div>
      <div className="">{/* empty */}</div>
    </section>
  );
};

const TableRow: FC<{
  label: ReactNode;
  etfs?: boolean;
  roboAdvisors?: boolean;
  frec?: boolean;
}> = ({ label, etfs, roboAdvisors, frec }) => {
  return (
    <>
      <div className="border-b dotted-h">{/* empty */}</div>
      <div className="border-b dotted-h bg-frecNeon">{/* empty */}</div>
      <div className="col-span-2 border-b dotted-h">{/* empty */}</div>
      <div className="flex items-center px-4 border-r lg:px-9 dotted-v text-balance">
        {label}
      </div>
      <div className="flex items-center justify-center px-2 border-r lg:px-9 dotted-v bg-frecNeon">
        <Check checked={frec} />
      </div>
      <div className="flex items-center justify-center px-2 border-r lg:px-9 dotted-v">
        <Check checked={etfs} />
      </div>
      <div className="flex items-center justify-center px-2 lg:px-9 dotted-v">
        <Check checked={roboAdvisors} />
      </div>
    </>
  );
};

const Check: FC<{ checked?: boolean }> = ({ checked }) => {
  return checked ? (
    <UilCheck
      className="w-6 h-6 lg:w-8 lg:h-8 text-frecBlack"
      aria-label="Yes"
    />
  ) : (
    <UilMinus
      className="w-6 h-6 lg:w-8 lg:h-8 text-frecBlack/30"
      aria-label="No"
    />
  );
};
