import Image, { StaticImageData } from "next/image";
import { FC } from "react";

import { Button } from "../../components/Button";
import Ashley from "../../public/webstatic/images/team/ashley-color.jpg";
import Dimitri from "../../public/webstatic/images/team/dimitri-color.jpg";
import { classNames } from "../../utils/classNames";
import { windowIfAvailable } from "../../utils/windowIfAvailable";

const licensedProfessionals = {
  ashley: {
    image: Ashley,
    name: "Ashley Rodabaugh",
    title: "Chief Compliance Officer",
  },
  dimitri: {
    image: Dimitri,
    name: "Dimitri Rodrigo",
    title: "Head of Brokerage Operations",
  },
};

export const ScheduleCall: FC<{
  title?: string;
  description?: string;
  className?: string;
}> = ({
  title = "Have questions?",
  description = "Meet with one of our licensed professionals who can give you an overview of Frec, answer your questions, and get you started with a free portfolio analysis.",
  className,
}) => {
  return (
    <section
      className={classNames(
        "grid grid-cols-[auto_minmax(0,_86rem)_auto]",
        className
      )}
    >
      {/* row */}
      <div className=""></div>
      <div className="grid grid-rows-[auto_auto] lg:grid-rows-none lg:grid-cols-2 dotted-v lg:border-x lg:mx-12">
        <div className="flex flex-col h-full gap-8 px-6 pt-12 lg:pt-24 lg:pb-24 lg:ml-12 dotted-v lg:border-r justify-center">
          <h2 className="h2">{title}</h2>
          <p className="lg:text-book20">{description}</p>
          <Button
            onClick={() => windowIfAvailable?.open("/15-min", "_blank")}
            className="w-full lg:w-fit"
            variant="blueoutline"
          >
            Schedule a call
          </Button>
        </div>
        <div className="px-6 py-12">
          <div className="relative flex flex-col justify-center h-full min-h-[400px] overflow-x-hidden items-center">
            <FrecklePanel
              info={licensedProfessionals.dimitri}
              className={classNames(
                "lg:w-[250px] lg:h-[250px] w-[200px] h-[200px] frec-shadow",
                "mt-24 lg:ml-60 ml-40"
              )}
            />
            <div className="relative">
              <FrecklePanel
                info={licensedProfessionals.ashley}
                className={classNames(
                  "lg:h-[250px] lg:w-[250px] w-[200px] h-[200px] frec-shadow relative",
                  "-mt-20 right-20"
                )}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="">{/* empty */}</div>
    </section>
  );
};

const FrecklePanel: FC<{
  info: { name: string; image: StaticImageData };
  className?: string;
}> = ({ info: { name, image }, className }) => {
  return (
    <div
      key={name}
      className={classNames(
        "absolute lg:w-[250px] lg:h-[250px] w-[200px] h-[200px] rounded-3xl shrink-0",
        className
      )}
    >
      <Image
        src={image}
        className="absolute object-cover w-full h-full rounded-3xl"
        alt={name}
      />
      {name === "Ashley Rodabaugh" && (
        <div className="-bottom-8 right-0 absolute bg-white rounded-lg p-6 border border-frecMidGray">
          How can we help?
        </div>
      )}
    </div>
  );
};
