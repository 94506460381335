import Image from "next/image";
import Link from "next/link";
import { FC } from "react";

import { TestimonialCategory } from "../content/testimonials";
import { PostType } from "../utils/blogApi";
import { classNames } from "../utils/classNames";
import { FrecWebPath } from "../utils/paths";

export const BlogCard: FC<{ post: PostType; className?: string }> = ({
  post,
  className,
}) => {
  return (
    <Link href={`${FrecWebPath.blog}/${post.slug}`}>
      <div
        className={classNames(
          "p-6 lg:p-16 lg:!pb-0 dotted-v hover:bg-frecDarkBeige/50 h-full",
          className
        )}
      >
        <div className="w-full bg-frecDarkBeige">
          {post.featured_image ? (
            <Image
              src={post.featured_image}
              width={391}
              height={255}
              className={classNames(
                "w-[214px] h-[155px] lg:w-[391px] lg:h-[255px]",
                post.categories.includes(TestimonialCategory)
                  ? "object-none object-left"
                  : "object-cover"
              )}
              alt=""
            />
          ) : null}
        </div>
        <h3 className="mt-8 lg:text-[24px] text-[16px] mb-4">{post.title}</h3>
      </div>
    </Link>
  );
};
