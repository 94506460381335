import { HelpCenterArticleLinks } from "@frec-js/common";
import { useTreasuryRatesQuery } from "@frec-js/common-client";
import { Accordion } from "@mantine/core";
import Image from "next/image";
import { FC } from "react";

import { FootnoteAnchor, IndexFootnotes } from "../../components/Anchor";
import { Button } from "../../components/Button";
import { Footnote, TextLink } from "../../components/Link";
import { useIsMobile } from "../../hooks/useIsMobile";
import LandingTreasuryPng from "../../public/webstatic/images/landing-treasury.png";
import ExpandableIcon from "../../public/webstatic/svg/expandable-icon.svg";
import { FadeInUp } from "../../utils/animations";
import { classNames } from "../../utils/classNames";
import { BrokerageWebPath } from "../../utils/paths";

export const Treasury: FC = () => {
  const { data } = useTreasuryRatesQuery();
  const maxSevenDayYieldPercentage =
    data?.treasuryRates.maxSevenDayYieldPercentage;
  const isMobile = useIsMobile();
  return (
    <section className="bg-frecDarkBeige grid grid-cols-[auto_minmax(0,_86rem)_auto] grid-rows-[1px_auto_1px_96px]">
      {/* row */}
      <div className="col-span-3">{/* empty */}</div>
      {/* row */}
      <div className="">{/* empty */}</div>
      <div className="grid lg:grid-cols-2 dotted-v lg:border-x lg:mx-12">
        <div className="grid grid-rows-[auto-auto-auto] dotted-v lg:border-r">
          <div className="flex flex-col px-6 mt-24 border-b lg:px-16 dotted-h pb-9">
            <div className="uppercase text-frecBlue lg:text-[24px] mb-4">
              Treasury
            </div>
            {maxSevenDayYieldPercentage && (
              <h2 className="h2">
                Earn up to {maxSevenDayYieldPercentage.toFixed(2)}%
                <Footnote anchor={FootnoteAnchor.TreasuryDisclaimer}>
                  <sup>{IndexFootnotes.withTreasuryDisclaimer}</sup>
                </Footnote>
              </h2>
            )}
          </div>
          <div className="px-6 lg:px-16 py-8 lg:text-book20">
            <p>
              Why let your cash sit in a low-earning traditional bank account
              when it could be making you more?
            </p>
            <div className="flex flex-col gap-4 my-8 lg:text-[18px]">
              <div className="flex items-center gap-2">
                <Tick />
                <p>Highly liquid & extremely secure</p>
              </div>
              <div className="flex items-center gap-2">
                <Tick />
                <p>Set up recurring deposits from your bank account</p>
              </div>
            </div>
            <Button
              variant="neon"
              small={isMobile}
              onClick={() => (location.href = BrokerageWebPath.signup)}
            >
              Start earning
            </Button>
          </div>
          <Accordion
            chevron={<ExpandableIcon />}
            className="border-t dotted-h"
            classNames={{
              item: "border-0",
              itemTitle: "",
              control: "px-10 hover:bg-frecBlack/5",
              label: "py-10 lg:text-book20 text-[18px]",
              icon: "",
              chevron: "text-book20 !w-8 rotating-chevron",
              panel: "",
              content: "p-10 pt-0",
            }}
          >
            <Accordion.Item value="risks">
              <Accordion.Control>
                What are the risks of investing in money market funds?{" "}
              </Accordion.Control>
              <Accordion.Panel>
                You should carefully review and consider the investment
                objectives, risks, charges, and expenses of the fund before
                investing. You can find this information in the fund’s
                prospectus, which can be located on the fund’s website.{" "}
                <TextLink
                  href={HelpCenterArticleLinks["Money Market funds"]}
                  target="_blank"
                >
                  Learn more
                </TextLink>
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="flex flex-col items-center justify-center px-6 py-8 lg:py-12 lg:pb-0 lg:px-20 dotted-h lg:border-t-none">
          <FadeInUp>
            <Image
              className="!mb-4"
              src={LandingTreasuryPng}
              alt="Screenshot of Treasury's auto-deposit feature"
            />
          </FadeInUp>
          <ImageDisclaimer className="mb-6" />
        </div>
      </div>
      <div className="">{/* empty */}</div>
      {/* row */}
      <div className="col-span-3 border-b dotted-h">{/* empty */}</div>
      {/* row */}
      <div className=""></div>
      <div className="dotted-v lg:border-x lg:mx-12">{/* empty */}</div>
      <div className="">{/* empty */}</div>
    </section>
  );
};

const Tick = () => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="relative w-6 h-6 shrink-0"
      preserveAspectRatio="none"
    >
      <path
        d="M18.7104 7.20998C18.6175 7.11625 18.5069 7.04186 18.385 6.99109C18.2632 6.94032 18.1324 6.91418 18.0004 6.91418C17.8684 6.91418 17.7377 6.94032 17.6159 6.99109C17.494 7.04186 17.3834 7.11625 17.2904 7.20998L9.84044 14.67L6.71044 11.53C6.61392 11.4367 6.49998 11.3634 6.37512 11.3142C6.25026 11.265 6.11694 11.2409 5.98276 11.2432C5.84858 11.2455 5.71617 11.2743 5.59309 11.3278C5.47001 11.3812 5.35868 11.4585 5.26544 11.555C5.1722 11.6515 5.09889 11.7654 5.04968 11.8903C5.00048 12.0152 4.97635 12.1485 4.97867 12.2827C4.98099 12.4168 5.00972 12.5493 5.06321 12.6723C5.1167 12.7954 5.19392 12.9067 5.29044 13L9.13044 16.84C9.2234 16.9337 9.334 17.0081 9.45586 17.0589C9.57772 17.1096 9.70843 17.1358 9.84044 17.1358C9.97245 17.1358 10.1032 17.1096 10.225 17.0589C10.3469 17.0081 10.4575 16.9337 10.5504 16.84L18.7104 8.67998C18.8119 8.58634 18.893 8.47269 18.9484 8.34619C19.0038 8.21969 19.0324 8.08308 19.0324 7.94498C19.0324 7.80688 19.0038 7.67028 18.9484 7.54378C18.893 7.41728 18.8119 7.30363 18.7104 7.20998V7.20998Z"
        fill="#112E33"
      />
    </svg>
  );
};

const ImageDisclaimer: FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={classNames("text-[12px] text-frecBlack/50", className)}
      data-nosnippet
    >
      For illustrative purposes only
    </div>
  );
};
