import { motion, useReducedMotion } from "framer-motion";
import { FC, PropsWithChildren } from "react";

import { useIsMobile } from "../hooks/useIsMobile";

const transition = {
  duration: 0.8,
  ease: "easeOut",
  type: "spring",
  // setting stiffness, damping or mass will override the duration
};

const viewport: { once: boolean; amount: number | "some" | "all" | undefined } =
  { once: true, amount: 0.2 };

type AnimationProps = {
  className?: string;
  delaySecs?: number;
  durationSecs?: number;
  amountInView?: number | "some" | "all" | undefined;
};

export const FadeInSide: FC<PropsWithChildren<AnimationProps & { fromLeft: boolean }>> = (
  props
) => {
  const {
    fromLeft,
    children,
    className,

    delaySecs = 0,
    durationSecs = transition.duration,
    amountInView = viewport.amount,
  } = props;
  const reducedMotion = useReducedMotion();
  const isMobile = useIsMobile();
  // side animations look silly on mobile, so just fadein up
  return isMobile ? (
    <FadeInUp {...props} />
  ) : (
    <motion.div
      className={className}
      initial={{
        opacity: 0,
        x: fromLeft ? -200 : 200,
      }}
      whileInView={{
        opacity: 1,
        transition: {
          ...transition,
          delay: delaySecs,
          duration: reducedMotion ? 0 : durationSecs,
        },
        x: 0,
      }}
      viewport={{ ...viewport, amount: amountInView }}
    >
      {children}
    </motion.div>
  );
};
export const FadeInLeft: FC<PropsWithChildren<AnimationProps>> = (props) => {
  return <FadeInSide {...props} fromLeft />;
};

export const FadeInRight: FC<PropsWithChildren<AnimationProps>> = (props) => {
  return <FadeInSide {...props} fromLeft={false} />;
};

export const FadeInUp: FC<PropsWithChildren<AnimationProps>> = ({
  children,
  className,
  delaySecs = 0,
  durationSecs = transition.duration,
  amountInView = viewport.amount,
}) => {
  const reducedMotion = useReducedMotion();
  return (
    <motion.div
      className={className}
      initial={{
        opacity: 0,
        y: 80,
      }}
      whileInView={{
        opacity: 1,
        transition: {
          ...transition,
          delay: delaySecs,
          duration: reducedMotion ? 0 : durationSecs,
        },
        y: 0,
      }}
      viewport={{ ...viewport, amount: amountInView }}
    >
      {children}
    </motion.div>
  );
};

export const FadeInZoom: FC<PropsWithChildren<AnimationProps>> = ({
  children,
  className,
  delaySecs = 0,
  durationSecs = transition.duration,
  amountInView = viewport.amount,
}) => {
  const reducedMotion = useReducedMotion();
  return (
    <motion.div
      className={className}
      initial={{
        opacity: 0,
        scale: 0.2,
        y: 0,
      }}
      whileInView={{
        opacity: 1,
        scale: 1,
        transition: {
          ...transition,
          delay: delaySecs,
          duration: reducedMotion ? 0 : durationSecs,
        },
        y: 0,
      }}
      viewport={{ ...viewport, amount: amountInView }}
    >
      {children}
    </motion.div>
  );
};
