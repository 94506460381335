import { Grid, Tooltip } from "@mantine/core";
import { DirectIndexingIcon } from "../../components/DirectIndexingIcon";
import {
  DirectIndexingTypeFunFact,
  DirectIndexingTypeText,
} from "@frec-js/common";
import { DirectIndexType } from "@frec-js/common/src/generated/graphql";
import { useDirectIndexingColor } from "@frec-js/common-client";
import { FC, useEffect, useRef, useState } from "react";
import animationData from "../../public/webstatic/lottie/customization-on-landing.json";
import lottie, { AnimationItem } from "lottie-web/build/player/lottie_light";
import { Info } from "../../components/Info";
import { exploreIndicesAnchor } from "../../components/Anchor";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useAvailableIndices } from "../../hooks/useAvailableIndices";

export const ChooseFromMultipleIndices = () => {
  const lottieRef = useRef<HTMLDivElement>(null);
  const animInstance = useRef<AnimationItem | null>(null);
  const [renderCount, setRenderCount] = useState(0);

  useEffect(() => {
    if (!lottieRef.current) return setRenderCount((c) => c + 1);

    animInstance.current = lottie.loadAnimation({
      container: lottieRef.current,
      renderer: "svg",
      loop: true,
      autoplay: false,
      animationData,
    });

    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;

      if (entry.isIntersecting) {
        if (!animInstance.current?.isPaused) {
          // If animation is playing, don't restart it
          return;
        }
        animInstance.current.goToAndPlay(0, true); // Start animation from beginning when it comes into view
      }
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // Use viewport as the root
      threshold: 0.1, // Start animation when 10% of element is in view
    });

    observer.observe(lottieRef.current);

    // Cleanup on component unmount
    return () => {
      observer.disconnect(); // Stop observing element
      animInstance.current?.destroy(); // Clean up animation instance
    };
  });

  const { availableIndices, loading } = useAvailableIndices();

  return (
    <section
      id={exploreIndicesAnchor}
      className="grid grid-cols-[auto_minmax(0,_86rem)_auto] grid-rows-[auto_1px_1fr] overflow-x-hidden bg-frecDarkBeige"
    >
      <div className="">{/* empty */}</div>
      <div className="flex flex-col justify-center dotted-v lg:border-x lg:pt-36 lg:pb-20 gap-24 lg:mx-12 py-12 px-6">
        <div className="text-center flex flex-col items-center gap-6">
          <h2 className="h2">Choose from multiple indices</h2>
          <p className="max-w-[715px]">
            {availableIndices && availableIndices.length > 0
              ? `Choose from ${availableIndices.length} options`
              : "Choose from multiple options"}{" "}
            spanning market caps, sectors, and regions. Our indices are licensed
            directly from leading brands like S&P<sup>®</sup> and Russell
            <sup>®</sup>.
          </p>
          <p className="max-w-[715px]">
            Don’t settle for ETFs—unlock the full potential of your investments.
          </p>
          {availableIndices && availableIndices.length > 0 && !loading && (
            <IndexChips indices={availableIndices} />
          )}
        </div>
        <div className="text-center flex flex-col items-center gap-6">
          <div className="text-center flex flex-col items-center gap-6">
            <div className="text-3xl font-normal">And make it your own.</div>
            <div className="max-w-[600px]">
              After choosing which indices to invest in, you can customize them
              by adding or excluding stocks or sectors. Make your portfolio
              personalized to you.{" "}
              <Info className="align-middle" position="bottom-start">
                <div className="text-xs">
                  You can add or exclude up to 10 stocks for all indices. You
                  can also exclude up to 2 sectors for all indices except for
                  the S&P 500 Information Technology Index and the MVIS US
                  Listed Semiconductor Index.
                </div>
              </Info>
            </div>
          </div>
          <div className="max-w-[700px]" ref={lottieRef}></div>
        </div>
      </div>
      <div className="">{/* empty */}</div>
    </section>
  );
};

const IndexChips: FC<{ indices: DirectIndexType[] }> = ({ indices }) => {
  const isMobile = useIsMobile();
  const [selectedIndex, setSelectedIndex] = useState<
    DirectIndexType | undefined
  >(undefined);

  return (
    <div className="w-full flex justify-center mt-8">
      <Grid
        justify="center"
        gutter={isMobile ? "xs" : "lg"}
        className="max-w-[1000px]"
      >
        {indices.map((indexType) => {
          const diColor = useDirectIndexingColor(indexType);
          return (
            <Grid.Col span="content" key={indexType}>
              <Tooltip
                className="border-white border-1 rounded-lg p-2 text-center"
                opened={selectedIndex === indexType}
                label={DirectIndexingTypeFunFact[indexType]}
                color={diColor}
                position="bottom"
                withArrow
                arrowSize={8}
                w={250}
                multiline
                transitionProps={{ duration: 300 }}
              >
                <div
                  className="rounded-full lg:py-3 py-2 lg:px-5 px-3 flex items-center lg:gap-3 gap-2 font-medium lg:text-base text-xs cursor-default border-2"
                  style={{
                    backgroundColor: "rgba(253, 252, 247, 0.5)",
                    borderColor:
                      selectedIndex === indexType ? diColor : "transparent",
                  }}
                  onMouseEnter={() => setSelectedIndex(indexType)}
                  onMouseLeave={() => setSelectedIndex(undefined)}
                  onClick={() => setSelectedIndex(indexType)}
                >
                  <DirectIndexingIcon
                    className="lg:w-7 lg:h-7 w-6 h-6"
                    type={indexType}
                    size="xs"
                  />
                  {DirectIndexingTypeText[indexType]}
                </div>
              </Tooltip>
            </Grid.Col>
          );
        })}
      </Grid>
    </div>
  );
};
